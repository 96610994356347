import React, { Component } from 'react';
import styled from 'styled-components';
import { Hotkeys, Hotkey, HotkeysTarget } from '@blueprintjs/core';
import { Box, Button, ButtonLink, Flex, FlexColumn, Grid, Icon, Link, Tag, Text } from 'core/components';
import { MdOpenInNew } from 'react-icons/md';
import { ReactComponent as EmptySearchIcon } from 'app/assets/icons/empty_search.svg';
import { ProductSkeleton } from 'core/components/Skeletons';
import { SearchSuggestions } from './SearchSuggestions';
import { RESULT_TYPE_MAP } from './results/ResultTypeMap';

const CategoryRow = styled(Flex)`
  justify-content: space-between;
  cursor: pointer;
`;

const resultsLoadingSkeleton = (
  <Grid m={3} gridGap="32px" gridRowGap="32px" gridTemplateColumns="1fr 250px">
    <Flex flexDirection="column" gap={4}>
      <ProductSkeleton forceDark width="100%" height="60px" />
      <ProductSkeleton forceDark width="100%" height="60px" />
      <ProductSkeleton forceDark width="100%" height="60px" />
      <ProductSkeleton forceDark width="100%" height="60px" />
      <ProductSkeleton forceDark width="100%" height="60px" />
      <ProductSkeleton forceDark width="100%" height="60px" />
    </Flex>

    <Flex flexDirection="column" gap={2}>
      <ProductSkeleton forceDark width="100%" height="32px" />
      <ProductSkeleton forceDark width="100%" height="32px" />
      <ProductSkeleton forceDark width="100%" height="32px" />
      <ProductSkeleton forceDark width="100%" height="32px" />
      <ProductSkeleton forceDark width="100%" height="32px" />
      <ProductSkeleton forceDark width="100%" height="32px" />
    </Flex>
  </Grid>
);

@HotkeysTarget
class SearchResults extends Component {
  state = {
    selectedIdx: 1
  };

  componentDidMount() {
    this.selectedItemRef = React.createRef();
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  renderHotkeys() {
    return (
      <Hotkeys>
        <Hotkey
          allowInInput
          preventDefault
          global
          group="Global Search Overlay"
          combo="up"
          label="Select results up"
          onKeyDown={() => {
            const { selectedIdx } = this.state;
            const listLength = this.getActiveList().length;
            this.setState({ selectedIdx: selectedIdx > 0 ? selectedIdx - 1 : listLength - 1 }, () => {
              /* eslint-disable no-unused-expressions */
              this.selectedItemRef?.current?.scrollIntoView?.({ behavior: 'smooth', block: 'nearest' });
            });
          }}
        />
        <Hotkey
          allowInInput
          preventDefault
          global
          group="Global Search Overlay"
          combo="down"
          label="Select results down"
          onKeyDown={() => {
            const { selectedIdx } = this.state;
            const listLength = this.getActiveList().length;
            this.setState({ selectedIdx: selectedIdx < listLength - 1 ? selectedIdx + 1 : 0 }, () => {
              /* eslint-disable no-unused-expressions */
              this.selectedItemRef?.current?.scrollIntoView?.({ behavior: 'smooth', block: 'nearest' });
            });
          }}
        />
        <Hotkey
          allowInInput
          preventDefault
          global
          group="Global Search Overlay"
          combo="enter"
          label="Open selected result"
          onKeyDown={() => {
            const { selectedIdx } = this.state;
            this.handleHotkeySelect(selectedIdx);
          }}
        />
      </Hotkeys>
    );
  }

  getActiveList() {
    const { categories, results } = this.props;
    return categories.reduce((acc, curr) => {
      const categoryRes = results.filter((result) => result.category === curr.name);
      if (!curr.active || !curr.filtered) {
        return acc;
      }
      if (!curr.expanded) {
        return acc.concat(categoryRes.slice(0, 5));
      }
      return acc.concat(categoryRes);
    }, []);
  }

  handleHotkeySelect(idx) {
    const { onItemClick } = this.props;
    const activeList = this.getActiveList();
    const item = activeList[idx];

    if (item) {
      onItemClick(
        { type: item.category, id: item.id || item.value, path: item.path, options: item.options || {} },
        'Search Results'
      );
    }
  }

  handleMouseOverRow(idx) {
    const { selectedIdx } = this.state;
    if (selectedIdx !== idx) {
      this.setState({ selectedIdx: idx });
    }
  }

  render() {
    const {
      categories,
      results,
      rawResults,
      searched,
      searching,
      onItemClick,
      query,
      handleToggleExpandCategory,
      handleToggleFilteredCategory
    } = this.props;
    const { selectedIdx } = this.state;

    if (!searched || query === '') {
      return <SearchSuggestions />;
    }

    if (searching) {
      return resultsLoadingSkeleton;
    }

    const activeCategories = new Set(categories.filter((cat) => cat.active && cat.filtered).map((cat) => cat.name));
    const expandedCategories = new Set(categories.filter((cat) => cat.expanded).map((cat) => cat.name));
    const filteredResults = results.filter((result) => activeCategories.has(result.category));
    const categorizedResults = {};
    filteredResults.forEach((res) => {
      if (!categorizedResults[res.category]) {
        categorizedResults[res.category] = {
          category: res.category,
          length: rawResults[res.category].length,
          results: []
        };
      }
      categorizedResults[res.category].results.push(res);
    });

    const categoryList = Array.from(activeCategories);

    if (results.length === 0 && !searching) {
      return (
        <Flex flexDirection="column" alignItems="center" justifyContent="center" pt={5} gap={1}>
          <Text fontSize={24} mb={2} lineHeight="26px">
            Sorry, nothing matched
          </Text>
          <Flex flexDirection="column" alignItems="center" justifyContent="center">
            <Text fontSize={16}>We couldn&apos;t find anything matching that word or phrase.</Text>
            <Text fontSize={16}>Please try something else, or check out the Knowledge Base.</Text>
          </Flex>
          <Icon icon={EmptySearchIcon} iconSize={250} mt={4} />
        </Flex>
      );
    }

    return (
      <>
        <Grid gridGap={0} gridTemplateColumns="3fr 1fr" gridRowGap={0} gridColumnGap="4px">
          <FlexColumn p={0} flex={3} overflow="auto" style={{ scrollbarWidth: 'none' }}>
            {categoryList.map((category, idx) => {
              if (!categorizedResults[category]) {
                return null;
              }
              const { length, results: catResults } = categorizedResults[category];
              const offset = categoryList
                .slice(0, idx)
                .reduce(
                  (acc, curr) => acc + Math.min(rawResults[curr].length, expandedCategories.has(curr) ? 1000 : 5),
                  0
                );
              const { Component: SearchResult, Icon: IconCmp } = RESULT_TYPE_MAP[category];
              return (
                <FlexColumn key={category} pb={1}>
                  <Box py={1} style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
                    <Text large>{RESULT_TYPE_MAP[category]?.label}</Text>
                  </Box>
                  <Flex flexDirection="column" gap="4px">
                    {catResults.slice(0, expandedCategories.has(category) ? 1000 : 5).map((result, resIdx) => {
                      const isSelectedItem = resIdx + offset === selectedIdx;
                      return (
                        <div
                          ref={isSelectedItem ? this.selectedItemRef : null}
                          key={`${result.category}.${result.id}`}
                          style={{ cursor: 'pointer' }}
                        >
                          <SearchResult
                            item={result}
                            onItemClick={onItemClick}
                            selected={isSelectedItem}
                            IconCmp={IconCmp}
                            // use onMouseMove instead of onMouseEnter/onMouseOver to avoid triggering on scroll
                            onMouseMove={() => this.handleMouseOverRow(resIdx + offset)}
                          />
                        </div>
                      );
                    })}
                  </Flex>
                  <Flex justifyContent="flex-end" height="16px">
                    {length > 5 && !expandedCategories.has(category) && (
                      <Button borderRadius="15px" minimal onClick={() => handleToggleExpandCategory(category)}>
                        {`+ ${length - 5} more`}
                      </Button>
                    )}
                  </Flex>
                </FlexColumn>
              );
            })}
            {filteredResults.length === 0 && (
              <Box textAlign="center" p={2}>
                <Text muted fontSize={18}>
                  No results matching selected categories
                </Text>
              </Box>
            )}
          </FlexColumn>
          <Box p={2}>
            <Flex flexDirection="column" flex={1}>
              {Array.from(categories)
                .filter((cat) => cat.name !== 'journey')
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((category) => {
                  const { filtered, name } = category;
                  const numResults = rawResults && rawResults[name] && rawResults[name].length;

                  if (!numResults || !name || !RESULT_TYPE_MAP[name]) {
                    console.warn('No results matching selected categories', { name });
                    return null;
                  }

                  const { label, Icon: CategoryIcon } = RESULT_TYPE_MAP[name];

                  return (
                    <CategoryRow
                      key={name}
                      py={1}
                      px="4px"
                      onClick={() => handleToggleFilteredCategory(name)}
                      borderRadius="4px"
                    >
                      <Flex alignItems="center">
                        <CategoryIcon color={filtered ? '#ffffff' : 'muted'} />
                        <Text
                          fontWeight="bold"
                          mx={1}
                          muted={!filtered}
                          ellipsis
                          overflow="hidden"
                          whiteSpace="nowrap"
                          width="100%"
                        >
                          {label || name}
                        </Text>
                      </Flex>
                      <Tag intent={filtered ? 'primary' : 'none'} color="#ffffff" minimal={!filtered} round>
                        {numResults}
                      </Tag>
                    </CategoryRow>
                  );
                })}
            </Flex>
            <Box textAlign="right">
              <ButtonLink small minimal onClick={() => handleToggleFilteredCategory('all')}>
                All
              </ButtonLink>
              <Text fontSize="small" mx={1}>
                |
              </Text>
              <ButtonLink small minimal onClick={() => handleToggleFilteredCategory('none')}>
                None
              </ButtonLink>
            </Box>

            {query && (
              <Box px="4px" py={1} my={2}>
                <Icon icon={MdOpenInNew} pr={1} />
                <Text color="#ffffff">See KB results for </Text>
                <Link to={`https://kb.kentik.com/v4/search.htm?uSearch=${query}`} blank>
                  {`"${query}"`}
                </Link>
              </Box>
            )}
          </Box>
        </Grid>
      </>
    );
  }
}

export default SearchResults;
