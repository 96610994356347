import React, { Component } from 'react';
import { Field, formConsumer, InputGroup, Switch, TextArea } from 'core/form';
import { Box, Card, Flex, Heading } from 'core/components';

@formConsumer
export default class CompanyPermissions extends Component {
  render() {
    return (
      <Flex>
        <Box mr={2}>
          <Card elevation={4} p={2} mb={2}>
            <Heading level="5" mb={2}>
              Kentik UI
            </Heading>
            <Flex>
              <Field name="permissions.portal.enabled" mr={2}>
                <Switch />
              </Field>
            </Flex>

            <Flex>
              <Field name="permissions.journeys" mr={2}>
                <Switch />
              </Field>
              <Field name="permissions.flowNLQ" mr={2}>
                <Switch />
              </Field>
              <Field name="permissions.fpa" mr={2}>
                <Switch />
              </Field>
              <Field name="permissions.tsp" mr={2}>
                <Switch />
              </Field>
            </Flex>
            <Flex>
              <Field name="permissions.kubeMap.enabled" mr={2}>
                <Switch />
              </Field>
              <Field name="permissions.linkSharing.disabled" mr={2}>
                <Switch />
              </Field>
              <Field name="permissions.trafficCosts.enabled" mr={2}>
                <Switch />
              </Field>
              <Field name="permissions.kproxyCapability.enabled" mr={2}>
                <Switch />
              </Field>
            </Flex>
            <Flex>
              <Field name="permissions.boundaryasn_override.enabled" mr={2}>
                <Switch />
              </Field>
              <Field name="permissions.segmentRouting.enabled" mr={2}>
                <Switch />
              </Field>
              <Field name="permissions.suppressLinksInExports" mr={2}>
                <Switch />
              </Field>
            </Flex>

            <Flex>
              <Field name="permissions.dashboards.max" mr={2}>
                <InputGroup />
              </Field>
              <Field name="permissions.reportSubscriptions.max" mr={2}>
                <InputGroup />
              </Field>
            </Flex>
          </Card>

          <Card elevation={4} p={2} mb={2}>
            <Heading level="5" mb={2}>
              Alerting
            </Heading>
            <Flex>
              <Field name="permissions.alerts.limits.activePolicies" mr={2}>
                <InputGroup />
              </Field>
              <Field name="permissions.alerts.limits.totalPolicies" mr={2}>
                <InputGroup />
              </Field>
            </Flex>
            <Flex>
              <Field name="permissions.alerts.limits.keysPerPolicy" mr={2}>
                <InputGroup />
              </Field>
              <Field name="permissions.alerts.limits.baselineKeysPerPolicy" mr={2}>
                <InputGroup />
              </Field>
            </Flex>
            <Flex>
              <Field name="permissions.alerts.flowSpec.enabled" mr={2}>
                <Switch />
              </Field>
              <Field name="permissions.alerts.mit2bgp.enabled" mr={2}>
                <Switch />
              </Field>
              <Field name="permissions.alerts.nmsNative.enabled" mr={2}>
                <Switch />
              </Field>
              <Field name="permissions.alerts.eventAlerting.enabled" mr={2}>
                <Switch />
              </Field>
              <Field name="permissions.alerts.allow30sPolicyWindow" mr={2}>
                <Switch />
              </Field>
            </Flex>
          </Card>

          <Card elevation={4} p={2} mb={2}>
            <Heading level="5" mb={2}>
              Custom Dimensions
            </Heading>
            <Flex>
              <Field name="permissions.customDimensions.maxDimensions" mr={2}>
                <InputGroup />
              </Field>
              <Field name="permissions.customDimensions.maxPopulators" mr={2}>
                <InputGroup />
              </Field>
            </Flex>
          </Card>

          <Card elevation={4} p={2} mb={2}>
            <Heading level="5" mb={2}>
              Journeys
            </Heading>
            <Flex>
              <Field name="permissions.journeys" mr={2}>
                <Switch />
              </Field>
              <Field name="permissions.journeysModel" mr={2}>
                <InputGroup />
              </Field>
            </Flex>
            <Flex flexAuto>
              <Field name="permissions.journeysPrompt" flex={1}>
                <TextArea fill />
              </Field>
            </Flex>
          </Card>
        </Box>
        <Box>
          <Card elevation={4} p={2} mb={2}>
            <Heading level="5" mb={2}>
              NMS
            </Heading>
            <Flex>
              <Field name="permissions.recon.enabled" mr={2}>
                <Switch />
              </Field>
              <Field name="permissions.recon.useNLKMQ" mr={2}>
                <Switch />
              </Field>
            </Flex>
          </Card>

          <Card elevation={4} p={2} mb={2}>
            <Heading level="5" mb={2}>
              Raw Flow
            </Heading>
            <Flex>
              <Field name="permissions.rawFlow.viewer" mr={2}>
                <Switch />
              </Field>
              <Field name="permissions.rawFlow.forensicViewer" mr={2}>
                <Switch />
              </Field>
            </Flex>
          </Card>

          <Card elevation={4} p={2} mb={2}>
            <Heading level="5" mb={2}>
              Service Provider
            </Heading>
            <Flex>
              <Field name="permissions.ott.enabled" mr={2}>
                <Switch />
              </Field>
              <Field name="permissions.cdn.enabled" mr={2}>
                <Switch />
              </Field>
              <Field name="permissions.marketIntel.enabled" mr={2}>
                <Switch />
              </Field>
            </Flex>
          </Card>

          <Card elevation={4} p={2} mb={2}>
            <Heading level="5" mb={2}>
              My Kentik Portal
            </Heading>
            <Flex>
              <Field name="permissions.subtenancy.advancedMode" mr={2}>
                <Switch />
              </Field>
              <Field name="permissions.subtenancy.disableVizTypeChanges" mr={2}>
                <Switch />
              </Field>
            </Flex>
            <Flex>
              <Field name="permissions.subtenancy.maxTenants" mr={2}>
                <InputGroup />
              </Field>
            </Flex>
          </Card>

          <Card elevation={4} p={2} mb={2}>
            <Heading level="5" mb={2}>
              Synthetics
            </Heading>
            <Flex>
              <Field name="permissions.synthetics.no_credit_limits" mr={2}>
                <Switch />
              </Field>
              <Field name="permissions.synthetics.bgpTests.enabled" mr={2}>
                <Switch />
              </Field>
              <Field name="permissions.synthetics.throughputTesting.enabled">
                <Switch />
              </Field>
            </Flex>
          </Card>

          <Card elevation={4} p={2} mb={2}>
            <Heading level="5" mb={2}>
              Cloud
            </Heading>
            <Flex>
              <Field name="permissions.cloud.metadataless_cloud_exports" mr={2}>
                <Switch />
              </Field>
            </Flex>
          </Card>
        </Box>
      </Flex>
    );
  }
}
