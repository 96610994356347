import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';

import storeLoader from 'app/stores/storeLoader';
import Page from 'app/components/page/Page';
import PageHeading from 'app/components/page/PageHeading';
import { Box, Flex, Tag, Text } from 'core/components';

import AdminTable from 'app/components/admin/AdminTable';

import { getCompanyStatusLabel, getEditionText } from 'app/stores/sudo/util';
import { dateTimeFormatter } from 'core/util/dateUtils';
import CompanyAdminFilterSidebar from 'app/views/admin/CompanyAdminFilterSidebar';

import { CELL_ACTIONS, CELL_TYPES } from 'core/components/table';

const PLAN_INTENT_MAP = {
  TRIAL: 'none',
  CUSTOMER: 'success',
  FREE: 'warning'
};
function companyNameRenderer(company) {
  return (
    <Flex>
      <Text as={Box} mr={1}>
        {company.get('company_name_full')}
      </Text>
      <Tag minimal intent={PLAN_INTENT_MAP[company.get('company_plan_id')]} small mr="4px">
        {company.companyPlan}
      </Tag>

      <Tag minimal small>
        {getEditionText(company.get('platform'))}
      </Tag>
    </Flex>
  );
}

@storeLoader('$sudo.companyAdminCollection')
@withRouter
@observer
export default class Admin extends Component {
  get columns() {
    return [
      {
        label: 'ID',
        name: 'id',
        width: 80
      },
      {
        label: 'Company Name',
        name: 'company_name_full',
        renderer: ({ model }) => companyNameRenderer(model),
        flexBasis: 350
      },
      {
        label: 'Status',
        name: 'company_status',
        renderer: ({ model }) => getCompanyStatusLabel(model),
        width: 125
      },
      {
        label: '24h p98 FPS',
        name: 'p98_fps_rate_limited'
      },
      {
        label: 'Active Devices',
        name: 'active_device_count',
        width: 125
      },
      {
        label: 'Total Devices',
        name: 'device_count',
        width: 125
      },
      {
        label: 'Users',
        name: 'user_count',
        width: 75
      },
      {
        label: 'Trial End',
        computed: true,
        name: 'trialEndDate',
        renderer: dateTimeFormatter
      },
      {
        label: 'Last Login',
        name: 'max_last_login',
        renderer: dateTimeFormatter
      },
      {
        label: 'Created',
        name: 'cdate',
        renderer: dateTimeFormatter
      },
      {
        type: CELL_TYPES.ACTION,
        actions: [CELL_ACTIONS.REMOVE]
      }
    ];
  }

  handleRowClick = (model) => {
    const { history } = this.props;
    history.push(`/v4/admin/${model.id}`);
  };

  render() {
    const { $sudo } = this.props;

    return (
      <Page title="Company Admin">
        <Flex flexDirection="column" flex={1} overflow="hidden">
          <Flex justifyContent="space-between">
            <PageHeading title="Company Admin" />
          </Flex>
          <AdminTable
            collection={$sudo.companyAdminCollection}
            filterSidebar={<CompanyAdminFilterSidebar collection={$sudo.companyAdminCollection} />}
            columns={this.columns}
            rowHeight={42}
            selectOnRowClick={false}
            onRowClick={this.handleRowClick}
            tabPrefId="company-admin"
          />
        </Flex>
      </Page>
    );
  }
}
