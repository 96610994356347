function toInt(value) {
  return value && parseInt(value);
}

const options = {
  groups: {
    featureFlags: ['permissions.sql.*'],
    customize: ['.*customDimensions.max.*']
  }
};

const fields = {
  'permissions.subtenancy.advancedMode': {
    label: 'Advanced MKP'
  },
  'permissions.subtenancy.disableVizTypeChanges': {
    label: 'Disable Visualization Changes'
  },
  'permissions.subtenancy.maxTenants': {
    label: 'Max Tenants',
    rules: 'numeric|min:0',
    transform: { out: toInt }
  },
  'permissions.synthetics.no_credit_limits': {
    label: 'Disable Synthetics Credit Limits'
  },
  'permissions.synthetics.bgpTests.enabled': {
    label: 'Enable BGP Tests'
  },
  'permissions.synthetics.throughputTesting.enabled': {
    label: 'Enable Throughput Testing'
  },
  'permissions.customDimensions.maxDimensions': {
    label: 'Custom Dimension Limit',
    rules: 'numeric|min:0',
    transform: { out: toInt }
  },
  'permissions.customDimensions.maxPopulators': {
    label: 'Populator Limit',
    rules: 'numeric|min:0',
    transform: { out: toInt }
  },
  'permissions.alerts.limits.activePolicies': {
    label: 'Active Policies Limit',
    rules: 'numeric|min:0|max:600'
  },
  'permissions.alerts.limits.totalPolicies': {
    label: 'Total Policies Limit',
    rules: 'numeric|min:0|max:720'
  },
  'permissions.alerts.limits.keysPerPolicy': {
    label: 'Keys Per Policy Limit',
    rules: 'numeric|min:0|max:20000'
  },
  'permissions.alerts.limits.baselineKeysPerPolicy': {
    label: 'Baseline Keys Per Policy Limit',
    rules: 'numeric|min:0|max:20000'
  },
  'permissions.alerts.nmsNative.enabled': {
    label: 'NMS Native Alerting'
  },
  'permissions.alerts.allow30sPolicyWindow': {
    label: 'Allow Policy Windows Under 60s'
  },
  'permissions.alerts.flowSpec.enabled': {
    label: 'FlowSpec'
  },
  'permissions.alerts.eventAlerting.enabled': {
    label: 'Event Based Alerting'
  },
  'permissions.alerts.mit2bgp.enabled': {
    label: 'Adaptive Flowspec'
  },
  'permissions.ott.enabled': {
    label: 'OTT Analytics'
  },
  'permissions.cdn.enabled': {
    label: 'CDN Analytics'
  },
  'permissions.marketIntel.enabled': {
    label: 'Market Intelligence'
  },
  'permissions.rawFlow.viewer': {
    label: 'Standard'
  },
  'permissions.rawFlow.forensicViewer': {
    label: 'Forensic'
  },
  'permissions.suppressLinksInExports': {
    label: 'Suppress Links in Exports'
  },
  'permissions.segmentRouting.enabled': {
    label: 'Experimental Segment Routing'
  },
  'permissions.dashboards.max': {
    label: 'Dashboard Limit',
    transform: { out: toInt }
  },
  'permissions.reportSubscriptions.max': {
    label: 'Subscription Limit',
    transform: { out: toInt }
  },
  'permissions.kubeMap.enabled': {
    label: 'Kube Map'
  },
  'permissions.linkSharing.disabled': {
    label: 'Link Sharing',
    transform: {
      in: (value) => !value,
      out: (value) => !value
    }
  },
  'permissions.portal.enabled': {
    label: 'Enable Portal Access',
    defaultValue: true
  },
  'permissions.recon.enabled': {
    label: 'NMS (Recon)'
  },
  'permissions.recon.useNLKMQ': {
    label: 'NMS: NLQ'
  },
  'permissions.flowNLQ': {
    label: 'Traffic: NLQ'
  },
  'permissions.journeys': {
    label: 'Journeys'
  },
  'permissions.journeysModel': {
    label: 'Journeys Model ID'
  },
  'permissions.journeysPrompt': {
    label: 'Journeys Prompt',
    transform: {
      in: (value) => (!value ? '' : value)
    }
  },
  'permissions.fpa': {
    label: 'FPA'
  },
  'permissions.tsp': {
    label: 'TSP (Baselines, Forecasting, etc...)'
  },
  'permissions.boundaryasn_override.enabled': {
    label: 'Manually Override Boundary ASN'
  },
  'permissions.trafficCosts.enabled': {
    label: 'Traffic (Flow Based) Costs'
  },
  'permissions.kproxyCapability.enabled': {
    label: 'Kproxy Capability'
  },
  'permissions.cloud.metadataless_cloud_exports': {
    label: 'Configure Metadataless Cloud Exports'
  }
};

export { fields, options };
